.Demo__some-network {
    vertical-align: top;
    display: inline-block;
    margin-right: 15px;
    text-align: center;
}

.Demo__some-network__share-count {
  margin-top: 3px;
  font-size: 12px;
}

.Demo__some-network__share-button {
  cursor: pointer;
}

.Demo__some-network__share-button:hover:not(:active) {
  opacity: 0.75;
}

.Demo__some-network__custom-icon {
  width:32px;
  height:32px;
}
.modal{
    background-color: rgba(250,250,250,0.5) !important;    
    width: fit-content !important;
}
.modal-content{
    width: fit-content !important;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #D4E157; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(159, 170, 63); 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.marker {
  background-image: url('https://www.mapbox.com/help/demos/custom-markers-gl-js/mapbox-icon.png');
  background-size: cover;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
}

.dropdown-content{
    top: 64px !important;
}

@media only screen and (min-width: 1024px){
    nav{
        background-color: white;
        background-image: url('https://firebasestorage.googleapis.com/v0/b/purejoy-honey.appspot.com/o/Web-Banner-Logo-64px-1440px.png?alt=media&token=ade26271-d474-492c-bb56-8bbf564d85e3');
        background-size: cover;
        /* height: 150px; */
    }
}

@media only screen and (max-width: 1024px){
    nav{
        background-color: white;
        background-image: url('https://firebasestorage.googleapis.com/v0/b/purejoy-honey.appspot.com/o/Web-Banner-Logo-64px-1024px.png?alt=media&token=f02d9588-d51d-43c6-ac35-b2030df8ca32');
        background-size: cover;
        /* height: 150px; */
    }
}

@media only screen and (max-width: 768px){
    nav{
        background-color: white;
        background-image: url('https://firebasestorage.googleapis.com/v0/b/purejoy-honey.appspot.com/o/Web-Banner-Logo-64px-768px.png?alt=media&token=37a9fd59-8a71-4ff4-97ef-79983337914d');
        background-size: cover;
        /* height: 150px; */
    }
}

@media only screen and (max-width: 425px){
    nav{
        background-color: white;
        background-image: url('https://firebasestorage.googleapis.com/v0/b/purejoy-honey.appspot.com/o/Web-Banner-Logo%2064px%20high.png?alt=media&token=f5f416a9-b070-4cc3-9049-e9e8028ace94');
        background-size: cover;
        /* height: 150px; */
    }
}

.Demo__some-network {
    vertical-align: top;
    display: inline-block;
    margin-right: 15px;
    text-align: center;
}

.Demo__some-network__share-count {
  margin-top: 3px;
  font-size: 12px;
}

.Demo__some-network__share-button {
  cursor: pointer;
}

.Demo__some-network__share-button:hover:not(:active) {
  opacity: 0.75;
}

.Demo__some-network__custom-icon {
  width:32px;
  height:32px;
}
.modal{
    background-color: rgba(250,250,250,0.5) !important;    
    width: -webkit-fit-content !important;    
    width: -moz-fit-content !important;    
    width: fit-content !important;
}
.modal-content{
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
}
/* .sidebar{
    min-height: 80vh; */
    /* overflow: auto; */
/* } */
.map{
    height: 80vh;
    padding: 0;
}
/* 
.sidebar .card-image{ */
    /* min-height: 200px; */
    /* max-height: 35vh;
    overflow: hidden;
}
.carousel{
    height: 100% !important;
} */
/* @media only screen and (max-width: 992px){ */
    /* .row{
        display: flex;
        flex-direction: column-reverse;
    } */
    /* .map{
        height: 500px;
    } */
    .carousel-item, .carousel{
        height: 100% !important;
        width: 100% !important;
    }
    .card-content{
        height: 300px;
        overflow: auto;
    }
    .card-image{
        max-height: 40vh;
        overflow: hidden;
    }

    /* .sidebar{
        min-height: 50vh;
        width: 40vw;
    } */
    /* .card-content{
        min-height: 10vh;
        max-height: 20vh;
        overflow: auto;
    }
    .card .card-content{
        padding: 0;
    }
    .card-title{
        font-size: 16px !important;
    }
    .card p{
        font-size: 12px !important;
    }
    .card{
        margin: 0;
    } */
    /* .sidebar .card-image{
        max-height: 30vh;
        width: 40vw;
        overflow: hidden;
    } */
    .mobileSidebar{
        height: 70vh;
        width: 250px;
        position: absolute;
        bottom: 20vh;
        left: 5vw;
        overflow: auto;
    }
    .expanded{
        height: 70vh;
        width: 90vw;
        position: absolute;
        bottom: 20vh;
        left: 5vw;
        overflow: auto;
    }
/* } */

@media only screen and (max-width: 600px){
    .carousel-item, .carousel{
        height: 100% !important;
        width: 100% !important;
    }
    .card-content{
        height: 100px;
        overflow: auto;
    }
    .mobileSidebar{
        height: 200px;
        width: 200px;
        position: absolute;
        bottom: 20vh;
        left: 5vw;
    }
    .expanded{
        height: 70vh;
        width: 90vw;
        position: absolute;
        bottom: 20vh;
        left: 5vw;
    }
}
.datatable{
    overflow: auto;
    width: inherit;
}
.datatable tr{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
}
.datatable td, .datatable th{
    display: inline-block;
    width: 200px !important;
    height: 70px !important;
    overflow: auto !important;
}


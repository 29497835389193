/* .sidebar{
    min-height: 80vh; */
    /* overflow: auto; */
/* } */
.map{
    height: 80vh;
    padding: 0;
}
/* 
.sidebar .card-image{ */
    /* min-height: 200px; */
    /* max-height: 35vh;
    overflow: hidden;
}
.carousel{
    height: 100% !important;
} */
/* @media only screen and (max-width: 992px){ */
    /* .row{
        display: flex;
        flex-direction: column-reverse;
    } */
    /* .map{
        height: 500px;
    } */
    .carousel-item, .carousel{
        height: 100% !important;
        width: 100% !important;
    }
    .card-content{
        height: 300px;
        overflow: auto;
    }
    .card-image{
        max-height: 40vh;
        overflow: hidden;
    }

    /* .sidebar{
        min-height: 50vh;
        width: 40vw;
    } */
    /* .card-content{
        min-height: 10vh;
        max-height: 20vh;
        overflow: auto;
    }
    .card .card-content{
        padding: 0;
    }
    .card-title{
        font-size: 16px !important;
    }
    .card p{
        font-size: 12px !important;
    }
    .card{
        margin: 0;
    } */
    /* .sidebar .card-image{
        max-height: 30vh;
        width: 40vw;
        overflow: hidden;
    } */
    .mobileSidebar{
        height: 70vh;
        width: 250px;
        position: absolute;
        bottom: 20vh;
        left: 5vw;
        overflow: auto;
    }
    .expanded{
        height: 70vh;
        width: 90vw;
        position: absolute;
        bottom: 20vh;
        left: 5vw;
        overflow: auto;
    }
/* } */

@media only screen and (max-width: 600px){
    .carousel-item, .carousel{
        height: 100% !important;
        width: 100% !important;
    }
    .card-content{
        height: 100px;
        overflow: auto;
    }
    .mobileSidebar{
        height: 200px;
        width: 200px;
        position: absolute;
        bottom: 20vh;
        left: 5vw;
    }
    .expanded{
        height: 70vh;
        width: 90vw;
        position: absolute;
        bottom: 20vh;
        left: 5vw;
    }
}
.dropdown-content{
    top: 64px !important;
}

@media only screen and (min-width: 1024px){
    nav{
        background-color: white;
        background-image: url('https://firebasestorage.googleapis.com/v0/b/purejoy-honey.appspot.com/o/Web-Banner-Logo-64px-1440px.png?alt=media&token=ade26271-d474-492c-bb56-8bbf564d85e3');
        background-size: cover;
        /* height: 150px; */
    }
}

@media only screen and (max-width: 1024px){
    nav{
        background-color: white;
        background-image: url('https://firebasestorage.googleapis.com/v0/b/purejoy-honey.appspot.com/o/Web-Banner-Logo-64px-1024px.png?alt=media&token=f02d9588-d51d-43c6-ac35-b2030df8ca32');
        background-size: cover;
        /* height: 150px; */
    }
}

@media only screen and (max-width: 768px){
    nav{
        background-color: white;
        background-image: url('https://firebasestorage.googleapis.com/v0/b/purejoy-honey.appspot.com/o/Web-Banner-Logo-64px-768px.png?alt=media&token=37a9fd59-8a71-4ff4-97ef-79983337914d');
        background-size: cover;
        /* height: 150px; */
    }
}

@media only screen and (max-width: 425px){
    nav{
        background-color: white;
        background-image: url('https://firebasestorage.googleapis.com/v0/b/purejoy-honey.appspot.com/o/Web-Banner-Logo%2064px%20high.png?alt=media&token=f5f416a9-b070-4cc3-9049-e9e8028ace94');
        background-size: cover;
        /* height: 150px; */
    }
}

.datatable{
    overflow: auto;
    width: inherit;
}
.datatable tr{
    display: flex;
    flex-wrap: nowrap;
}
.datatable td, .datatable th{
    display: inline-block;
    width: 200px !important;
    height: 70px !important;
    overflow: auto !important;
}